import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import CategoriesList from 'components/CategoriesList';
import { SliderVariant } from 'components/Slider';
import CardListing from 'containers/CardListing';
import Carousel from 'containers/Carousel';

import { useFilters } from 'hooks/useFilters';

const ArticlesListingPage: FC<ArticlesListingPageTypes.ArticlesListingProps> = ({
  data: {
    articlesListing: {
      urls,
      seo,
      bannerWithoutCropp,
      tags,
      dropdownPlaceholder,
      filtersTitle,
      productCarousel,
    },
    allArticleDetail: { articlesList },
    allProductDetail: { productsList },
  },
}) => {
  const { title, keywords, description } = seo;

  const { PRODUCTS } = SliderVariant;

  const {
    selectedCategories,
    sortedItems: sortedArticles,
    allTags,
    handleSelectedCategories,
  } = useFilters(articlesList, 'all');

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {bannerWithoutCropp?.imageBannerDesktop || bannerWithoutCropp?.imageBannerMobile ? (
        <Banner
          imageBannerDesktop={bannerWithoutCropp.imageBannerDesktop}
          imageBannerTablet={bannerWithoutCropp.imageBannerTablet}
          imageBannerMobile={bannerWithoutCropp.imageBannerMobile}
          {...bannerWithoutCropp.banner[0]}
        />
      ) : null}
      <CategoriesList
        {...{
          filtersTitle,
          dropdownPlaceholder,
          allTags,
          tags,
          selectedCategories,
          handleSelectedCategories,
        }}
      />
      <CardListing cards={sortedArticles} />
      {productsList.length && productCarousel ? (
        <Carousel
          carouselItems={productsList}
          carouselVariant={PRODUCTS}
          carousel={productCarousel}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $productsUrls: [String], $path: [String]) {
    articlesListing(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      dropdownPlaceholder
      filtersTitle
      tags {
        ...TagFragment
      }
      bannerWithoutCropp {
        banner {
          ...BannerFragment
        }
        imageBannerDesktop {
          alt
          imageData {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        imageBannerMobile {
          alt
          imageData {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        imageBannerTablet {
          alt
          imageData {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      productCarousel {
        ...CarouselFragment
      }
    }

    allArticleDetail {
      articlesList: nodes {
        ...ArticleCardFragment
        articleCardOrder
      }
    }

    allProductDetail(filter: { url: { in: $productsUrls } }) {
      productsList: nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default ArticlesListingPage;
